<template>
  <CyMenu
    v-model="isFilterOpened"
    :close-on-content-click="false"
    :nudge-width="200"
    :left="$vuetify.breakpoint.width < 1035"
    content-class="filter"
    offset-y>
    <template #activator="{ on }">
      <span>
        <CyButton
          theme="primary"
          variant="tertiary"
          icon="arrow_drop_down"
          icon-append
          v-on="on"
          @click="setRadioValue">
          {{ label }}
        </CyButton>
      </span>
    </template>
    <v-card class="items">
      <v-row class="px-6">
        <v-col :class="[{ 'filter__list--loading': _.some(_.values(fetchInProgress)) }]">
          <v-progress-circular
            v-if="_.some(_.values(fetchInProgress))"
            indeterminate
            color="secondary"
            class="mx-auto"/>
          <v-radio-group
            v-else
            v-model="radio"
            :mandatory="false">
            <v-radio
              v-for="({ title, value }) in options.items"
              :key="`radio-item-${title}`"
              :class="[
                'py-1',
                { 'font-weight-bold': _.isEqual(radio, value) },
              ]"
              :label="title"
              :value="value"
              outlined
              color="secondary"/>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-card-actions>
        <CyButton
          theme="primary"
          variant="tertiary"
          @click="reset">
          {{ $t('forms.btnReset') }}
        </CyButton>
        <v-spacer/>
        <CyButton
          theme="primary"
          variant="tertiary"
          @click="() => $toggle.isFilterOpened(false)">
          {{ $t('forms.btnCancel') }}
        </CyButton>
        <CyButton
          theme="secondary"
          variant="tertiary"
          @click="apply">
          {{ $t('forms.btnApply') }}
        </CyButton>
      </v-card-actions>
    </v-card>
  </CyMenu>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'

/**
 * Currently this filter component is designed to handle boolean type query params.
 * To set it up, the filtering options items need to have (at least) a following format:
 *
 * items: [{ title: 'All/Disable', value: null }, { title: 'Nope', value: 0 }, { title: 'Yasss!', value: 1 }]
 *
 * The first item is used to disable the filter, second is falsy and the third is truthy
 */
export default {
  name: 'CyDataTableRadioFilter',
  props: {
    options: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isFilterOpened: false,
    radio: -1,
  }),
  computed: {
    ...mapState('organization', {
      fetchInProgress: (state) => state.fetchInProgress,
    }),
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    radioFilterKey () {
      return `${this.options.queryParams[0]}[eq]`
    },
    currentFilter () {
      const filters = { ...this.getDataTableFilters(this.$route.name) }
      return _.isEmpty(filters) ? -1 : Number(filters[this.radioFilterKey])
    },
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
    ]),
    reset () {
      this.radio = -1
    },
    setRadioValue () {
      this.radio = _.isNull(this.currentFilter) ? -1 : this.currentFilter
    },
    apply () {
      const { $route: { name } } = this
      const filters = { ...this.getDataTableFilters(name) }

      this.radio < 0
        ? delete filters[this.radioFilterKey]
        : filters[this.radioFilterKey] = String(this.radio)

      this.SET_DATA_TABLE_FILTERS({ name, filters })
      this.isFilterOpened = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  color: get-color("primary");
}
</style>
